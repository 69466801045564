import React from 'react';

import BackgroundImage from 'gatsby-background-image';

import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Title from '@atoms/Title/Title';
import Text from '@atoms/Text/Text';
import HeaderBackBtn from '../HeaderBackBtn/HeaderBackBtn';

import CallToScrollDown from '@components/CallToScrollDown/CallToScrollDown';

// Styles
import './HeaderMinimal.scss';

HeaderMinimal.defaultProps = {
    title: 'My Defautl Title',
    spacing: 'xl',
    container: 'large',
    theme: 'dark',
};

function HeaderMinimal(props) {
    let mod_class = ['HeaderMinimal', props.size ? 'size-' + props.size : ' '];
    mod_class = mod_class.join(' ');

    return (
        <BackgroundImage
            Tag={`section`}
            id={`media-test`}
            className={'my-class'}
            fluid={props.imageSrc}
        >
            <div className={mod_class}>
                <Section container={props.container} spacing={props.spacing}>
                    <div className="HeaderMinimal--inner">
                        {props.showBackBtn ? <HeaderBackBtn /> : ''}
                        <Title
                            tag="h1"
                            size={props.subtitle ? 'xxl' : 'giant'}
                            font="alt"
                            align="center"
                            theme="white"
                        >
                            {props.title}
                        </Title>
                        {props.subtitle && (
                            <div className="HeaderMinimal--subtitle">
                                <div className="HeaderMinimal--subtitleInner">
                                    <Title
                                        tag="h2"
                                        theme="white"
                                        size="m"
                                        align="center"
                                        noMargin
                                    >
                                        {props.subtitle}
                                    </Title>
                                </div>
                            </div>
                        )}

                        <Text theme="white">{props.text}</Text>
                        <Divider spacing="xs" invisible />
                        <div className="HeaderMinimal--ctaGroups gf g_center_mobile g_center">
                            <div className="HeaderMinimal--cta">
                                <div className="HeaderMinimal--btnPrimary">
                                    {props.btnPrimary}
                                </div>

                                {/* Render Secondary Button */}
                                {props.btnSecondary !== undefined ? (
                                    <div>{props.btnSecondary}</div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </Section>

                <div className="HeaderMinimal--CallToScroll">
                    <CallToScrollDown align="center" theme="white" />
                </div>
            </div>
        </BackgroundImage>
    );
}

export default HeaderMinimal;
